@import '../../../node_modules/antd/dist/antd.less';
//@import '~antd/dist/antd.dark.less'; // Introduce the official dark less style entry file

.App {
  text-align: center;
}

.layout-content {
  min-height: calc(100vh - 140px);
  margin: 0 auto;
  width: 90vw;
  max-width: 100%;
  padding: 20px 0;
}

.layout-sider {
  min-height: 100vh;
}

.layout-logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 24px 16px 0;
  line-height: 31px;
  font-weight: bold;
  text-align: center;
  float: left;
  color: #fff;

  &-dev {
    background: #058458;
  }
}

.layout-logo-test {
  width: 40px;
  height: 31px;
  background: #603a88;
  margin: 16px 24px 16px 0;
  line-height: 31px;
  font-weight: bold;
  text-align: center;
  float: left;
  color: #fff;

  &:after {
    content: "TEST"
  }
}

.layout-menu-right {
  float: right;
}

.layout-header-role-admin {
  background-color: #841805;

  ul {
    background-color: #841805 !important;;
  }

  .ant-menu-item-selected {
    background-color: #841805 !important;;
  }
}

.div-column {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.div--highlighted {
  &:hover {
    background-color: #eee;
  }
}
.div-row-spaced {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@primary-color: #246BCE;@layout-header-background: #243665;