.find-portfolio-page-container{
    width: 100%;
    .flex{
        display: flex;
    }
    flex-direction: column;
    .portfolio-filter-container{
        flex-direction: column;
        gap: 10px;
        padding-top: 15px;
        padding-bottom: 15px;
        .filter-form{
            gap: 10px;
            .ant-select-selector{
                width: 250px!important;
            }
            textarea{
                width: 150px;
            }    
        }
        .saved-filters{
            flex-direction: row;
            gap: 10px;
            flex-wrap: wrap;
            .filter-card{
                flex-direction: row;
                background-color: transparent;
                background-color: white;
                border-radius: 4px;
                overflow: hidden;
                white-space: nowrap;
                cursor: pointer;
                &:hover{
                    white-space: unset;
                }
                .label-container{
                    .label{
                        max-width: 282px;
                      
                        overflow: hidden;
                        text-overflow: ellipsis;
                       
                    }
                    padding:4px;
                    transition:all .3s;
                   
                    &:hover{
                        background-color: #246BCE;
                        color: white
                    }
                    
                }
                .delete-icon{
                    background-color: grey;

                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 20px;
                    height: 100%;
                    transition:all .3s;
                    &:hover{
                        background-color: #243665;

                    }
                }
            }
        }
    }
    .portfolio-table-container{
        .expanded-row{
            background-color: #243665;
        }
    }
}
@primary-color: #246BCE;@layout-header-background: #243665;